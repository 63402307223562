@import "~bootstrap/dist/css/bootstrap-grid.css";
@import "~bootstrap/dist/css/bootstrap-utilities.css";
@import "~ngx-toastr/toastr.css";

/*********************************
        FONTS
*********************************/
@font-face {
    font-family: 'Mont';
    src: url('/assets/konnex/fonts/Mont-Black.woff2') format('woff2'),
        url('/assets/konnex/fonts/Mont-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('/assets/konnex/fonts/Mont-Bold.woff2') format('woff2'),
        url('/assets/konnex/fonts/Mont-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('/assets/konnex/fonts/Mont-Heavy.woff2') format('woff2'),
        url('/assets/konnex/fonts/Mont-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('/assets/konnex/fonts/Mont-SemiBold.woff2') format('woff2'),
        url('/assets/konnex/fonts/Mont-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('/assets/konnex/fonts/Mont-Light.woff2') format('woff2'),
        url('/assets/konnex/fonts/Mont-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('/assets/konnex/fonts/Mont-Regular.woff2') format('woff2'),
        url('/assets/konnex/fonts/Mont-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*********************************
        PREDEFINED CLASSES
*********************************/
* {
    box-sizing: border-box;
}

.container-custom {
    padding: 0 150px;
}

body {
    font-family: 'Mont';
    color: black;
    margin: 0;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
}

/*********************************
        HEADER
*********************************/
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 105px 0;
}

.logo-box {
    height: 121.5px;
    width: auto;
}

.logo-box a {
    display: block;
    width: 100%;
    height: 100%;
}

.logo-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.time-box {
    font-size: 170px;
    line-height: 1.27;
    font-weight: 500;
    text-align: center;
}

.date-box {
    font-size: 80px;
    line-height: 1.27;
    font-weight: 500;
    text-align: center;
}

/*********************************
        PAGE HEAD
*********************************/
.page-head-block {
    padding: 220px 0 178px 0;
}

.page-head-block h2 {
    font-size: 178px;
    line-height: 1.3;
    font-weight: 700;
    text-align: left;
    margin: 0;
    width: 80%;
}

.btn {
    font-weight: 600;
    font-size: 64px;
    line-height: 1.5;
    text-align: center;
    color: white;
    text-decoration: none;
    display: inline-block;
    margin-top: 60px;
    padding: 40px 150px;
    background: linear-gradient(to right, rgba(29, 164, 228, 1), rgba(122, 186, 173, 1), rgba(164, 202, 120, 1), rgba(200, 217, 36, 1));
    transition: all 0.3s;
}

.btn:hover {
    filter: brightness(110%);
}

/*********************************
        FIXED DIVS
*********************************/
.half-circle {
    position: absolute;
    bottom: 130px;
    left: 0;
    width: 1100px;
    height: 2100px;
    opacity: .6;

    img {
        height: 100%;
        width: auto;
        object-fit: contain;
    }
}

.bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 225px;
    background: linear-gradient(to right, rgba(29, 164, 228, 1), rgba(122, 186, 173, 1), rgba(164, 202, 120, 1), rgba(200, 217, 36, 1));
}

.side-buttons {
    position: absolute;
    top: 2300px;
    right: 0;
    z-index: 6;

}

.lang-btn {
    background: linear-gradient(to right, rgba(29, 164, 228, 1), rgba(122, 186, 173, 1), rgba(164, 202, 120, 1), rgba(200, 217, 36, 1));
    width: 150px;
    height: 150px;
    transition: all 0.3s;
    color: white;
    font-size: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

}

.lang-btn img {
    width: 80px;
    height: 80px;
    filter: brightness(0) saturate(100%) invert(96%) sepia(98%) saturate(0%) hue-rotate(338deg) brightness(102%) contrast(100%);
}

.lang-menu {
    display: flex;
    gap: 50px;
    list-style-type: none;
    position: absolute;
    right: 208px;
    top: 0;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s;
}

input.hidden {
    width: 0;
    height: 0;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    visibility: unset;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

.question-btn a {
    background: linear-gradient(to right, rgba(29, 164, 228, 1), rgba(122, 186, 173, 1), rgba(164, 202, 120, 1), rgba(200, 217, 36, 1));
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 80px;
    text-decoration: none;
    margin-top: 60px;
}

.lang-btn:hover {
    filter: brightness(110%);
}

.question-btn {
    transition: all 0.3s;
}

.question-btn:hover {
    filter: brightness(110%);
}


.welcome-carousel {
    width: 100%;
    height: 1540px;
    position: relative;


    .carousel-item {
        display: none;

        &.active {
            display: block;
        }

        img {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

/*********************************
        MAIN PAGE
*********************************/
.page-top {
    padding: 315px 0 90px 0;
    transition:  all .3s;
}

.page-top h3 {
    font-weight: 600;
    font-size: 100px;
    line-height: 1.27;
    text-align: start;
    margin: 0;
}

.main-page {
    background-color: #F3F3F3;
    z-index: 5;
    position: relative;
    padding: 128px 0;
}

.check-main-page {
    padding-bottom: 0;
}

.main-page-block {
    height: 1860px;

}

.main-page-block h2 {
    font-weight: 700;
    font-size: 180px;
    line-height: 1.27;
    text-align: start;
    margin: 0;
    max-width: 1660px;
}

.main-page-block h3 {
    font-weight: 600;
    font-size: 96px;
    line-height: 1.27;
    text-align: start;
    margin: 0;
    margin-top: 82px;
}

.main-page-buttons {
    margin-top: 110px;
    display: grid;
    grid-template-columns: 450px 450px 450px;
    column-gap: 80px;
    row-gap: 60px;
    overflow-y: auto;
    max-height: 1265px;
    width: fit-content;
    scroll-snap-type: y mandatory;

    .main-page-btn {
        scroll-snap-align: start;
        scroll-snap-stop: always;
    }
}
.keyboard-open .page-top {
    padding-top: 60px;
}
.main-page-buttons::-webkit-scrollbar {
    display: none;
}

.hosts-container {
  position: relative;
}
.search-btn {
    position: absolute;
    right: 60px;
    width: 80px;
    height: 80px;
    border: 4px solid #84bf31;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -450px;
    img {
      width: 45px;
      filter: brightness(0) saturate(100%) invert(80%) sepia(14%) saturate(2179%) hue-rotate(35deg) brightness(88%) contrast(81%);
    }
}


.scroll-btn {
  position: absolute;
  right: 60px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  background-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  filter: brightness(0) saturate(100%) invert(80%) sepia(14%) saturate(2179%) hue-rotate(35deg) brightness(88%) contrast(81%);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 4px solid #84bf31;
}

.top-scroll {
  top: 570px;
  background-image: url('/assets/kowerk/icons/arrow-up.svg');
}
.bottom-scroll {
  top: auto;
  bottom: 160px;
  background-image: url('/assets/kowerk/icons/arrow-down.svg');
}
.big-call-modal {
    .exit-btn,
    .scroll-btn {
        right: 145px;
    }
    .bottom-scroll {
        bottom: 265px;
    }
    .top-scroll {
        top: auto;
        bottom: 750px;
    }
}
/*.main-page-buttons::-webkit-scrollbar-button:start:decrement,
.hosts-block::-webkit-scrollbar-button:end:increment {
    display: block;
}

.main-page-buttons::-webkit-scrollbar-button:vertical:start:increment,
.main-page-buttons::-webkit-scrollbar-button:vertical:end:decrement {
    display: none;
}

.main-page-buttons::-webkit-scrollbar-track:vertical {
    background: transparent;
}

.main-page-buttons::-webkit-scrollbar-track-piece {
    display: none;
}

.main-page-buttons::-webkit-scrollbar-thumb:vertical {
    display: none;
}

.main-page-buttons::-webkit-scrollbar-button:start:decrement,
.main-page-buttons::-webkit-scrollbar-button:end:increment {
    display: block;
}

.main-page-buttons::-webkit-scrollbar-button:start:increment,
.main-page-buttons::-webkit-scrollbar-button:end:decrement {
    display: none;
}

.main-page-buttons::-webkit-scrollbar-button:end:increment {
    background-image: url('/assets/konnex/icons/arrowdown.svg');
    width: 142px;
    height: 142px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 60%;
}

.main-page-buttons::-webkit-scrollbar-button:start:decrement {
    background-image: url('/assets/konnex/icons/arrowup.svg');
    width: 142px;
    height: 142px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 60%;
}*/

.check-out-page .modal-backdrop.show {
    opacity: .5 !important;
}

.call-modal-box {
    width: 100%;
    padding-top: 300px;
    padding-bottom: 260px;
    position: absolute;
    top: 825px;
    left: 0;
    background-color: rgba(243, 243, 243, .94) !important;
    z-index: 6;
    bottom: 680px;
    .modal-button{
       .btn {
        margin: 0;
       }
    }
    &.touchpad-modal-open {
        .numpad-keys {
        padding-top: 0;
        }
        .call-modal-button,
        .call-modal-img-box {
        display: none;
        }
        
        .call-modal-text-box,
        .tenant-name {
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin: 0;
        }
        .numpad-btn img {
        filter: brightness(0) saturate(100%) invert(42%) sepia(13%) saturate(2297%) hue-rotate(100deg) brightness(95%) contrast(88%);
        }
    }

    &.solid {
        background-color: rgba(243, 243, 243, 1) !important;
    }

    h2 {
        font-size: 80px;
        text-align: center;
        margin-top: 50px;
    }

    .numpad-btn {
        img {
        width: 180px;
        }
        button {
        border: none;
        background-color: transparent;
        }
        .numpad-text {
            font-size: 65px;
        }
        .numpad-text-open {
            display: none;
        }
        .numpad-text-active .numpad-text-hidden{
            display: none;
        }
        .numpad-text-active .numpad-text-open{
            display: block;
            color: #198754;
        }
    }
}

.big-call-modal {
    padding-top: 180px;
    padding-bottom: 180px;
    bottom: 225px;
    .modal-button{
        bottom: unset;
        position: relative;
        left: unset;
        transform: none;
    }
    .call-modal-text-box {
        max-width: 1400px;
        margin-bottom: 65px;
    }
    .call-modal-img-box {
        height: 225px;
        width: 450px;
    }
}


.call-modal-img-box {
    width: 520px;
    height: 260px;
    border: 2px solid black;
    border-radius: 11px;
    background-color: white;
    margin: 0 auto;
    padding: 50px;
    transition: all 0.3s;

    img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}

.call-modal-img-box a {
    display: block;
    width: auto;
    height: 100%;
}


.call-modal-text-box {
    font-size: 64px;
    line-height: 1.2;
    max-width: 1200px;
    margin-top: 96px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &.wider {
        max-width: 1500px;
    }
}

.wide-modal-text {
    max-width: 1500px;
    margin-bottom: 50px;
}

.call-modal-icons {
    display: flex;
    justify-content: center;
    gap: 1000px;
    margin-top: 70px;
}

.modal-red-box,
.modal-green-box {
    display: block;
    border-radius: 50%;
    margin: 0 !important;
    width: 250px !important;
    height: 250px !important;
    border: none;
    transition: all 0.3s;
}

.modal-red-box {
    background-color: rgba(220, 53, 69, 1);
}

.modal-green-box {
    background-color: #84BF31;
}

.modal-red-box img,
.modal-green-box img {
    width: 135px;
    padding: 10px !important;
}

.main-page-buttons button {
    text-decoration: none;
    background: none;
    color: black;
    display: block;
    border: none;
    padding: 0;
    border-radius: 11px;
}

.btn-img-box {
    padding: 30px;
    width: 450px;
    height: 180px;
    border: 2px solid black;
    border-radius: 11px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

}

.btn-img-box img {
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
}

.btn-img-box:hover {
    background-color: rgb(247, 245, 245);
}

.btn-text-box {
    font-weight: 600;
    font-size: 42px;
    line-height: 1.27;
    text-align: center;
    margin-top: 13px;
    word-break: break-word;
    overflow-wrap: break-word; 
}

.exit-btn {
    position: absolute;
    top: 40px;
    right: 210px;
    z-index: 10;
    border-radius: 50%;
    width: 76px;
    height: 82px;
    transition: all 0.3s;
}

.exit-btn a,
.exit-btn button,
.next-btn a,
.next-btn button {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    background: none;
}

.exit-btn img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.exit-btn:hover {

    overflow: hidden;
}


.next-btn {
    position: absolute;
    bottom: 120px;
    right: 200px;
    z-index: 10;
    width: 146px;
    height: 146px;
    border-radius: 50%;
    transition: all 0.3s;
}

.next-btn:hover {
    background-color: #d3d3d3;
    overflow: hidden;
}

.next-btn img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.contact-btn {
    margin-top: 210px;
}

.contact-btn-img-box {
    width: 970px;
    height: 250px;
    border: 2px solid black;
    border-radius: 11px;
    background-color: white;
    margin: 0 auto;
    padding: 0 210px;
    transition: all 0.3s;
}

.contact-btn-img-box:hover {
    background-color: rgb(221, 221, 221);
}

.contact-btn-img-box a {
    display: block;
    width: auto;
    height: 100%;
}

.contact-btn-img-box img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.contact-btn-text-box {
    margin-top: 50px;
    font-size: 80px;
    line-height: 1.27;
    text-align: center;
}

.call-box {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(243, 243, 243, .94);
    padding-top: 320px;
    box-sizing: border-box;
}

.call-box-second {
    background-color: rgba(243, 243, 243, 1);
    padding-top: 104px;
}

.call-box-text-box {
    font-size: 80px;
    line-height: 1.2;
    color: #6C757D;
    max-width: 1124px;
    margin-top: 96px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.black-call-text {
    color: black;
}

.wide-call-text {
    color: black;
    max-width: 1800px;
    font-size: 76px;
}

.call-icons-box {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
}

.call-red-box {
    display: block;
    background-color: rgba(220, 53, 69, 1);
    border-radius: 50%;
    width: 280px;
    height: 280px;
    border: none;
    transition: all 0.3s;
}

.call-red-box:hover {
    background-color: rgb(165, 41, 53);
}

.call-red-box img {
    object-fit: contain;
    width: 150px;
    height: 150px;
}

.call-green-box {
    display: block;
    background-color: #84BF31;
    border-radius: 50%;
    width: 280px;
    height: 280px;
    border: none;
    transition: all 0.3s;
}

.call-green-box:hover {
    background-color: #74a82b;
}

.call-green-box img {
    object-fit: contain;
    width: 110px;
    height: 110px;
}

.home-page-block {
    height: 1500px;
}

.home-page-block h2 {
    font-weight: 700;
    font-size: 176px;
    line-height: 1.27;
    text-align: start;
    margin: 0;
    max-width: 2000px;
}

.home-page-block h3 {
    font-weight: 600;
    font-size: 120px;
    line-height: 1.27;
    text-align: start;
    margin: 0;
    margin-top: 92px;
}

.home-page-buttons {
    display: flex;
    flex-direction: column;
    gap: 77px;
    padding-top: 100px;
}

.home-btn {
    width: 1150px;
    height: 152px;
    line-height: 1.14;
    font-weight: 500;
    text-align: center;
    padding: 5px;
    background: linear-gradient(to right, rgba(29, 164, 228, 1), rgba(122, 186, 173, 1), rgba(164, 202, 120, 1), rgba(200, 217, 36, 1));
}

.home-btn a {
    width: 1140px;
    height: 142px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    font-size: 60px;
    transition: all 0.3s;
}

.home-btn a:hover {
    background-color: #F3F3F3;
}

.numbers-box {
    display: grid;
    grid-template-columns: 168px 168px 168px;
    row-gap: 55px;
    column-gap: 64px;
    padding: 100px 90px;
    border: 5px solid black;
    background-color: white;

}

.number {
    font-size: 68px;
    line-height: 1.05;
    font-weight: 800;
    width: 150px;
    height: 150px;
    border: 5px solid black;
    background-color: white;
    transition: all 0.3s;
    cursor: pointer;
}

.number:active {
    background-color: rgb(197, 197, 197);
}

.gray-number {
    background-color: #E8E8E8;
}

.green-number {
    background: linear-gradient(to right, rgba(29, 164, 228, 1), rgba(122, 186, 173, 1), rgba(164, 202, 120, 1), rgba(200, 217, 36, 1));
    border: none;
    padding: 5px;
    transition: all 0.3s;
}

.green-number:hover .number-img-box {
    background-color: #6a962c;
}

.number-img-box {
    background-color: #84BF31;
    height: 140px;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number-img-box img {
    object-fit: cover;
}

.home-page-content {
    display: flex;
    gap: 100px;
    padding-top: 70px;
}

.home-right-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.home-img-box {
    width: 227px;
    height: 315px;
}

.home-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.number-box {
    margin-bottom: -180px;
}

.code-text-number-box {
    text-align: center;
    display: block;
    width: 84px;
    font-size: 150px;
    font-weight: 500;
    height: auto;
    font-family: var(--font-primary);
    margin-right: 56px;
}

.bottom-buttons-block {
    display: flex;
    justify-content: space-between;
    padding-top: 96px;
    gap: 70px;
}

.left-side {
    display: flex;
    align-items: center;

}

.left-side-img-box {
    width: 120px;
    height: 120px;
    border-radius: 50%;

}

.left-side-img-box a {
    display: block;
    border-radius: 50%;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
}

.left-side-img-box a:hover {
    background-color: #F3F3F3;
}

.left-side-img-box img {
    object-fit: contain;
    width: 120px;
    height: 120px;
}

.left-side-text-box {
    font-weight: 500;
    font-size: 65px;
    line-height: 1.14;
    margin-left: 60px;
    z-index: 5;
}

.right-side {
    display: flex;
    align-items: center;
    max-width: 1060px;
}

.right-side-img-box {
    width: 120px;
    height: 120px;
    border-radius: 50%;

}

.right-side-img-box a {
    display: block;
    border-radius: 50%;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
}

.right-side-img-box a:hover {
    background-color: #F3F3F3;
}

.right-side-img-box img {
    object-fit: contain;
    width: 120px;
    height: 120px;
}

.right-side-text-box {
    font-weight: 500;
    font-size: 65px;
    line-height: 1.14;
    margin-right: 60px;
    text-align: right;
}

.home-page-text-box {
    padding-left: 220px;
}

.check-page-text {
    padding-top: 50px;
}

.home-page-text-head {
    font-size: 80px;
    font-weight: 800;
    line-height: 1.33;
}

.home-page-text {
    font-size: 80px;
    font-weight: 500;
    line-height: 1.33;
}

.home-page-text span {
    font-weight: 800;
}

.black-bar {
    width: 100%;
    background-color: black;
    color: white;
    font-weight: 300;
    font-size: 72px;
    line-height: 1.33;
    padding-left: 230px;
    padding-top: 85px;
    padding-bottom: 85px;
    padding-right: 230px;
    margin-top: 160px;
    margin-bottom: 220px;
}

.home-check-box {
    width: 474px;
    height: 474px;
    position: absolute;
    top: 120px;
    right: 80px;
    svg {
        path {
            stroke: #84bf31;
        }
    }
}
.modal-button {
    .btn {
        border: none;
    }
}
.check-box {
    max-height: 2105px;
    position: relative;
    margin-top: 120px;
    min-height: 250px;
}

.check-home-page {
    height: auto;
}

.check-image-box {
    width: 100%;
    height: 1120px;
}

.check-image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.check-modal-box {
    padding: 205px 140px 140px 190px;
}

.check-modal-head {
    font-weight: 700;
    font-size: 100px;
    text-transform: uppercase;
    letter-spacing: -1px;
    margin-bottom: 80px;
}

.check-modal-img-box {
    height: 900px;
    width: 100%;
    margin-bottom: 80px;
}

.check-modal-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.check-modal-text-box p {
    font-weight: 500;
    font-size: 75px;
    line-height: 1.33;
    margin-bottom: 70px;
}

.check-exit-btn {
    right: 80px;
    top: 80px;
}

.vertical-buttons {
    display: flex;
    flex-direction: column;
    gap: 76px;
    position: absolute;
    right: 275px;
    bottom: 200px;
}

.vertical-btn {
    border: none;
    background-color: transparent;
    width: 189px;
    height: 189px;
}

.success-modal {
    .modal-dialog {
        width: 90%;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
    }
    .modal-content {
        padding: 220px 100px;
    }
    
   .check-modal-text-box {
        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        h1,h2,h3,h4,h5 {
          margin-bottom: 32px;
          margin-top: 0;
          line-height: 1.2;
        }
        h1 {
          font-size: 160px; 
          font-weight: 700;
        }
        h2 {
          font-size: 138px;
          font-weight: 600;
        }
        h3 {
          font-size: 106px;
          font-weight: 600;
        }

        h4 {
          font-size: 95px;
          font-weight: 500;
        }
        h5 {
          font-size: 90px;
          font-weight: 500;
        }
        p {
          font-size: 80px;
          margin-bottom: 32px; 
        }
        ul,ol {
          padding-left: 120px;
        }
        li {
          font-size: 80px;
          margin-bottom: 24px;
        }
   }
}



.call-cta-box {
    padding-left: 150px;
    padding-right: 400px;
    padding-top: 50px;

    ul {
        padding: 0;
        margin: 0;
        height: 1220px;
        overflow-y: scroll;
        scroll-snap-type: y mandatory;

        li {
            list-style-type: none;
            border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
            scroll-snap-align: start;
            scroll-snap-stop: always;
        }
    }
    ul::-webkit-scrollbar {
        display: none;
    }
}

.list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cta-text-box {
    font-weight: 800;
    font-size: 64px;
    line-height: 1.27;
}

.cta-btn-box {
    display: flex;
    align-items: center;
    padding: 67px 0;
    background-color: transparent;
    gap: 37px;
    border: none;
}

.cta-btn-box p {
    font-weight: 600;
    font-size: 50px;
    margin: 0;
    line-height: 1.27;
}

.green-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #84BF31;
    border-radius: 50%;
    width: 170px;
    height: 170px;
    border: none;
    transition: all 0.3s;
}

.green-box img {
    object-fit: contain;
    width: 82px;
    height: 82px;
}



/*** TOASTR ***/
.toast-top-right {
    top: 50px;
    right: 50px;
}

.toast-container .toast-error {
    background-color: #000000 !important;
    font-size: 60px;
    font-weight: 500;
    color: #FFFFFF !important;
    border-radius: 11px;
    margin-bottom: 50px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.toast-container>div {
    background-position: 50px;
    background-repeat: no-repeat;
    background-size: 100px 100px;
    padding: 70px 70px 70px 200px;
    background-image: url(/assets/konnex/icons/exiticon-white.svg);
}

/*** OVERLAY ***/
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.75);
    overflow-x: hidden;
    transition: 0.5s;

    &.modal {
        background-color: rgba(112, 109, 109, 0.5);

        .modal-dialog {
            position: absolute;
            top: 1000px;
            z-index: 6;
            background: #ffffff;
            margin: 0 160px;
        }
    }

    h1 {
        font-size: 120px;
    }

    h3 {
        font-size: 80px;
    }

    &.white {
        background-color: rgba(255, 255, 255, 0.85);

        .overlay-content {
            color: #000000;
            top: 50%;
            padding: 0 300px;
        }
    }

    .loading {
        $colors: #ffffff,
            #ffffff,
            #ffffff,
            #ffffff,
            #ffffff;
        display: flex;

        .dot {
            font-size: 16px;
            position: relative;
            width: 2em;
            height: 2em;
            margin: 0.8em;
            border-radius: 50%;

            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: inherit;
                border-radius: inherit;
                animation: wave 2s ease-out infinite;
            }

            @for $i from 1 through 5 {
                &:nth-child(#{$i}) {
                    background: nth($colors, $i);

                    &::before {
                        animation-delay: $i * 0.2s;
                    }
                }
            }
        }
    }

    @keyframes wave {

        50%,
        75% {
            transform: scale(2.5);
        }

        80%,
        100% {
            opacity: 0;
        }
    }

    .overlay-content {
        position: relative;
        top: 50%;
        margin-top: -600px;
        width: 100%;
        text-align: center;
        padding: 0 300px;
        color: #FFFFFF;

        h5 {
            text-transform: none;
            font-size: 48px;
            transition: 0.3s;
            margin: 100px 0;
        }

        img {
            height: 300px;
        }
    }
}
/********* WAREHOUSE XS *********/
.warehouse-xs {
    &.call-modal-box {
        transition: all .3s;
        padding-top: 40px;
        padding-bottom: 30px;
        top: 265px;
        bottom: 145px;
            .call-modal-icons {
                gap: 110px;
                margin-top: 35px;

                .modal-red-box,
                .modal-green-box {
                    width: 80px !important;
                    height: 80px !important;
                    img {
                        width: 60px;
                    }
                }
                &.touchpad-modal-open {
                    .numpad-keys {
                    padding-top: 0;
                    }
                    .call-modal-img-box {
                    display: none;
                    }
                    .call-modal-text-box,
                    .tenant-name {
                    height: 0;
                    opacity: 0;
                    visibility: hidden;
                    margin: 0;
                    }
                    .numpad-btn img {
                    filter: brightness(0) saturate(100%) invert(42%) sepia(13%) saturate(2297%) hue-rotate(100deg) brightness(95%) contrast(88%);
                    }
                }
            }
        .numpad-btn {
            img {
                width: 60px;
            }
            .numpad-text {
                font-size: 20px;
            }
        }    
    }
    &.success-modal {
        .modal-content {
            padding: 50px 30px;
        }
        .check-modal-text-box {
            img {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            }
            h1,h2,h3,h4,h5 {
            margin-bottom: 16px;
            margin-top: 0;
            line-height: 1.2;
            }
            h1 {
            font-size: 36px; 
            font-weight: 700;
            }
            h2 {
            font-size: 28px;
            font-weight: 600;
            }
            h3 {
            font-size: 25px;
            font-weight: 600;
            }

            h4 {
            font-size: 22px;
            font-weight: 500;
            }
            h5 {
            font-size: 20px;
            font-weight: 500;
            }
            p {
            font-size: 16px;
            margin-bottom: 16px; 
            }
            ul,ol {
            padding-left: 32px;
            }
            li {
            font-size: 16px;
            margin-bottom: 16px;
            }
        }
    }
    &.numpad-modal {   
        top: 150px;
    }
    .main-page-block h2 {
        max-width: 550px;
    }
    .scroll-btn {
        position: absolute;
        right: 33px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-color: transparent;
        user-select: none;
        -webkit-user-select: none;
        -webkit-tap-highlight-color: transparent;
        filter: brightness(0) saturate(100%) invert(80%) sepia(14%) saturate(2179%) hue-rotate(35deg) brightness(88%) contrast(81%);
        border-radius: 50%;
        border: 2px solid #84bf31;
     }

    .top-scroll {
        top: 92px;
        background-image: url('/assets/kowerk/icons/arrow-up.svg');
    }
    .bottom-scroll {
        top: auto;
        bottom: 100px;
        background-image: url('/assets/kowerk/icons/arrow-down.svg');
    }
    .search-btn {
        top: 30px;
        width: 30px;
        right: 33px;
        height: 30px;
        border: 2px solid #84bf31;
        img {
            width: 17px;
        }
    }

    &.big-call-modal {
        .call-modal-text-box {
            margin: 16px auto;
        }
        .exit-btn {
            right: 32px;
        }
        .scroll-btn {
            right: 55px;
        }
        .top-scroll {
            top: 23px;
        }
        .bottom-scroll {
            bottom: 34px;
        }
    }
    .btn {
        font-size: 22px !important;
        padding: 12px 40px !important;
        margin: 0;
        border: none;
    }
    .page-head-block {
        padding: 40px 0;
    }
    .container-custom {
        padding: 0 40px;
    }
    &.container-fluid {
        padding: 0 40px;
    }
    .logo-box {
        height: 35px;
    }
    .time-box {
        font-size: 50px;
    }
    .date-box {
        font-size: 30px;
    }
    .top-bar {
        padding: 25px 0;
    }
    .page-head-block h2 {
        font-size: 60px;
        margin-bottom: 20px;
    }
    .welcome-carousel {
        height: 480px;
    }
    &.side-buttons{
        top: auto;
        bottom: 25%;
    }
    &.half-circle {
        bottom: 10px;
        width: auto;
        max-width: 100%;
        height: 554px;
    }
    .question-btn a,
    .lang-btn {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }
    .question-btn a {
        margin-top: 20px;
    }
    .lang-btn img {
        height: 30px;
        width: 30px;
    }
    .lang-menu {
        gap: 12px;
        right: 60px;
    }
    &.overlay h1 {
        font-size: 65px;
    }
    &.overlay .overlay-content {
        padding: 40px !important;
        top: 50% !important;
        transform: translateY(-50%);
        margin-top: 0;
    }
    &.overlay .loading .dot {
        background: #FFFFFF !important;
    }
    &.overlay .overlay-content h5 {
        margin: 50px 0 !important;
        font-size: 40px !important;
    }
    &.overlay .overlay-content img {
        height: 200px !important;
    }
    .page-top {
        padding: 40px 0 30px 0;
    }
    &.numpad-open .page-top,
    &.keyboard-open .page-top {
        padding: 0;
        height: 0;
    }

    .page-top h3 {
        font-size: 36px;
    }
    .main-page-block h2 {
        font-size: 55px;
    }
    &.keyboard-open .main-page-block h2 {
        display: none;
    }
    &.keyboard-open .main-page-block {
        height: 470px;
    }
    .btn-img-box {
        height: 65px;
        width: 100%;
        padding: 15px;
        border: 1px solid #000000;
        border-radius: 5px;
    }
    .btn-img-box img {
        max-width: 100%;
    }
    .main-page-buttons {
        margin-top: 20px;
        column-gap: 20px;
        row-gap: 12px;
        grid-template-columns: 170px 170px 170px;
        padding: 0;
        max-height: 420px;
    }
    .help-page-buttons {
        height: 265px;
    }
    .main-page-buttons button {
        width: 100%;
    }
    .btn-text-box {
        font-size: 16px;
        margin-top: 5px;
    }
    .main-page-buttons::-webkit-scrollbar {
        width: 120px;
    }
    .main-page-buttons::-webkit-scrollbar-button:end:increment {
        width: 42px;
        height: 42px;
    }
    .main-page-buttons::-webkit-scrollbar-button:start:decrement {
        width: 42px;
        height: 42px;
    }
    &.main-page {
        padding: 40px 0 40px 0;
    }
    .main-page-block {
        height: 550px;
    }
    .exit-btn {
        height: 30px;
        right: 50px;
        top: 20px;
    }
    &.big-call-modal{
        top: 266px;
        padding: 40px 0;
        bottom: 75px;
    }
    .call-modal-img-box {
        width: 200px;
        height: 65px;
        padding: 10px 20px;
        border-radius: 2px;
        border: 1px solid #000000;
    }
    .call-modal-text-box {
        font-size: 20px;
        margin-top: 20px;
        max-width: 450px;
    }
    .tenant-name {
        font-size: 25px;
        margin-top: 15px;
    }
    .call-cta-box {
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 150px;
    }
    .cta-text-box {
        font-size: 20px;
        font-weight: 700;
    }
    .green-box {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
    }
    .green-box img {
        max-height: 25px;
        max-width: 25px;
    }
    .cta-btn-box {
        gap: 10px;
        padding: 16px 0;
        flex-shrink: 0;
    }
    .cta-btn-box p {
        font-size: 18px;
    }
    .call-cta-box ul {
        height: 345px;
    }
    .main-page-block h3 {
        font-size: 36px;
    }
    .close {
        width: 30px;
        height: auto;
        margin-left: auto;
        margin-right: 30px;
    }
    .close a {
        display: block;
    }
    .padlock img {
        height: 250px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .title {
        font-size: 36px;
    }
    &.controls-block-alto {
        padding: 0 !important;
        background-color: #FFFFFF;
    }
    .dispenser-schema {
        max-width: 100%;
    }
    .log-container {
        height: 450px !important;
    }
    &.controls-block-alto .btn {
        padding: 10px 2px !important;
        min-height: 64px;
    }
    .home-page-block h2 {
        font-size: 65px;
    }
    .home-page-text-head {
        font-size: 22px;
    }
    .home-page-text-box {
        padding-left: 65px;
    }
    .home-page-text {
        font-size: 25px;
    }
    .check-page-text {
        padding-top: 15px;
        padding-bottom: 25px;
    }
    .home-check-box {
        height: 120px;
        width: 120px;
        top: 45px;
        right: 35px;
    }
    .black-bar {
        font-size: 20px;
        padding: 25px 80px 25px 60px;
        margin-top: 45px;
        margin-bottom: 10px;
    }
    .home-page-content {
        padding-top: 20px;
    }
    .numbers-box {
        padding: 30px;
        row-gap: 20px;
        column-gap: 25px;
        grid-template-columns: 55px 55px 55px;
        border-width: 2px;

    }
    .number {
        font-size: 24px;
        width: 54px;
        height: 54px;
        border-width: 2px;
    }
    .number img {
        width: 30px;
    }
    .code-text-number-box {
        font-size: 50px;
        width: 20px;
        margin-right: 25px;
    }
    .home-img-box {
        height: 100px;
    }
    .number-box {
        margin-bottom: -60px;
    }
    .home-page-content {
        gap: 60px;
    }
    .home-page-block {
        min-height: 360px;
        height: fit-content;
    }
    .left-side {
        position: relative;
        z-index: 50;
    }
    .left-side-text-box {
        font-size: 20px;
        margin-left: 20px;
    }
    .right-side-text-box {
        font-size: 20px;
        margin-right: 20px;
    }
    .left-side-img-box,
    .right-side-img-box {
        width: 40px;
        height: 50px;
        flex-shrink: 0;
    }
    .left-side-img-box img,
    .right-side-img-box img {
        height: 100%;
        width: 100%;
    }
    .bottom-buttons-block {
        padding-top: 20px;
    }
    .home-btn {
        width: 400px;
        height: 55px;
        padding: 2px;
    }
    .home-btn a {
        width: 100%;
        height: 100%;
        font-size: 22px;
    }
    .home-page-buttons {
        padding-top: 25px;
        gap: 25px;
    }
}

